<template>
  <div class="floor-edit">
    <div class="floor" v-if="floor">{{ floor }} этаж</div>
    <div class="floor-btns" >
<!--      <div class="edit-floor"  @click="startEditing">-->
<!--        <img src="../../assets/images/edit-button.svg" alt=""></div>-->
      <div class="delete-floor" @click="toggleDeleteFloor">
        <img src="../../assets/images/cancel-btn.svg" alt="">
      </div>
    </div>
    <DeletionConfirm v-if="showDeleteFloor"
      title="Вы действительно хотите удалить этот этаж?"
      :description="`${floor} этаж`"
      @close="toggleDeleteFloor"
      @delete="deleteFloor(block.id,entrance.entrance,floor)"
    />
  </div>
</template>

<script>
import DeletionConfirm from "./DeletionConfirm"

export default {
  name: "EditFloor",
  components: {DeletionConfirm},
  props:{
    floor:{
      type:String,
      default: "",
    },
    block:{
      type:Object,
      default: () => {},
    },
    entrance:{
      type:Object,
      default: () => {},
    }
  },
  data(){
    return{
      showEditFloor:false,
      showDeleteFloor: false,
      isUpdateFloor:false,
    }
  },
  methods:{
    deleteFloor(block,entrance,floor){
      this.$emit('delete-floor',block,entrance,floor)
    },
    startEditing(){
      this.isUpdateFloor=true;
    },
    toggleDeleteFloor() {
      this.showDeleteFloor = !this.showDeleteFloor;
    },
  }
}
</script>

<style scoped lang="scss">
.floor-edit{
  position: relative;
  //background: #2c3e50;
  height: 30px;
  &:hover{
    .floor-btns{
      display: block;
    }
  }
  .floor{
    position: relative;
  }
  .floor-btns{
    display: none;
    position: absolute;
    margin-top: 20px;
    margin-left: 5px;
  }

}
</style>